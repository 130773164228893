<!-- 
	This is the DataTables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>

	<div>

		<!-- Searchable Datatable card -->
		<a-card :bordered="false" class="header-solid mb-24 px-25" :bodyStyle="{padding: 0, paddingTop: '16px'}">
			<a-row class="mx-25 pt-10">
				<a-col :span="12">
					<h5 class="font-semibold">List of Classrooms</h5>
				</a-col>
				<a-col :span="12" class="text-right">
					<a-button type="primary" @click="onCreateClassroom" class="px-20 mb-10">
						<a-icon type="plus" theme="outlined" class="text-white mr-5" />Add New 
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-0 pt-0"/>
				</a-col>
			</a-row>
			

			<div class="mx-25 mt-15 pt-10">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" :md="12">
						<a-select v-model="pagination.perPage" :options="pagination.perPageOptions" style="width: 70px"/>
						<label for="" class="ml-10">entries per page</label>
					</a-col>
					<a-col :span="24" :md="12" class="text-right">
    					<a-input-search  
							placeholder="search name..." 
							style="max-width: 250px;" 
							v-model="pagination.search"  
      						@search="onSearchItem">
							<template #suffix v-if="pagination.search != null && pagination.search.length > 0">
								<a-icon type="close" theme="outlined" class="text-danger mr-10"  @click="onClearSearchItem" />
							</template>
						</a-input-search>
					</a-col>
				</a-row>
			</div>
			
			<div class="mx-25 pb-25">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" :md="24">
						<a-table class="mt-20"  
							size="middle"
							:bordered="false"
							:columns="columns" 
							:data-source="classrooms" 
							:rowKey="record => record.uuid"
							:loading="pagination.loading" 
							:pagination="false">

								<template slot="s_no" slot-scope="s_no, record, index">
									{{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
								</template>

								<template slot="academicYear" slot-scope="academicYear">
									{{ academicYear.name }}
								</template>

								<template slot="grade" slot-scope="grade">
									{{ grade.name }}
								</template>

								<template slot="section" slot-scope="section">
									{{ section.codeName != 'none' ? section.name : "" }}
								</template>

								<template slot="teacher" slot-scope="teacher">
									{{ teacher != null ? `${teacher.firstName} ${teacher.lastName}` : '-' }}
								</template>

								<template slot="isActive" slot-scope="isActive">
									<a-badge  
										style="font-size: 10px !important" 
										:count="isActive ? 'Active' : 'Inactive'" 
										:number-style="isActive ? { backgroundColor: '#52C41A', color: '#FFFFFF'} : { backgroundColor: '#D9D9D9', color: '#444', boxShadow: '0 0 0 1px #d9d9d9 inset'}"/>
								</template>
								
								<template slot="createdAt" slot-scope="createdAt">
									{{ $Moment(createdAt).format("DD MMM YYYY") }}
								</template>

								<template slot="action" slot-scope="action, record">
									<a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #188C54;" :href="`/classroom-management/classrooms/view/${record.uuid}`">
										<a-icon type="eye" /> View
									</a-button>
								</template>
							
						</a-table>
					</a-col>
					<a-col :span="12" class="mt-25 text-muted">
						<p class="category">Showing {{from + 1}} to {{to}} of {{pagination.total}} entries</p>
					</a-col>
					<a-col :span="12" class="text-right mt-25">
						<a-pagination 
							v-model="pagination.currentPage" 
							:pageSize="pagination.perPage"  
							:total="pagination.total" 
							@change="onCurrentPageChange"/>
					</a-col>
				</a-row>
			</div>
		</a-card>


		<!-- FORM MODAL -->
		<a-modal v-model="classroom.showModal" :footer="null">
			<h4 class="text-center">{{ classroom.isCreate ? 'Add Classroom' : 'Edit Classroom'}}</h4>
			<a-form-model
				:model="classroom"
                :form="form"
                class="login-form mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmit">

				<a-form-item 
					class="mt-20 mb-10 pb-0" 
					label="Academic Year *"
					:colon="false">
						<a-select 
							show-search
							v-model="classroom.academicYearUuid" 
							placeholder="Select Academic Year"
							:options="academicYears.map(option => ({ label: option.name, value: option.uuid }))" />
				</a-form-item>

				<a-form-item 
					class="mt-20 mb-10 pb-0" 
					label="Grade *"
					:colon="false">
						<a-select 
							show-search
							v-model="classroom.gradeUuid" 
							placeholder="Select Grade"
							:options="grades.map(option => ({ label: option.name, value: option.uuid }))" />
				</a-form-item>

				<a-form-item 
					class="mt-20 mb-10 pb-0" 
					label="Section *"
					:colon="false">
						<a-select 
							show-search
							v-model="classroom.sectionUuid" 
							placeholder="Select Section"
							:options="sections.map(option => ({ label: option.name, value: option.uuid }))" />
				</a-form-item>

				<a-form-item 
					class="mt-20 mb-10 pb-0" 
					label="Description"
					:colon="false">
						<a-textarea :rows="4" placeholder="" v-model="classroom.description"/>
				</a-form-item>

				<a-form-item class="mt-20"> 
					<a-button type="primary" :loading="classroom.loading" html-type="submit" class="">
						{{ classroom.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form-model>
			
		</a-modal>

	</div>

</template>

<script>

	// Second table's list of columns.
	const columns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'ACADEMIC YEAR',
			dataIndex: 'academicYear',
			// sorter: (a, b) => a.firstName.length - b.firstName.length,
			// sortDirections: ['descend', 'ascend'],
			scopedSlots: { customRender: 'academicYear' },
		},
		{
			title: 'GRADE',
			dataIndex: 'grade',
			scopedSlots: { customRender: 'grade' },
		},
		{
			title: 'SECTION',
			dataIndex: 'age',
			scopedSlots: { customRender: 'age' },
		},
		{
			title: 'CLASS TEACHER',
			dataIndex: 'teacher',
			scopedSlots: { customRender: 'teacher' },
		},
		{
			title: 'NO. OF STUDENTS',
			dataIndex: 'count',
			scopedSlots: { customRender: 'count' },
		},
		{
			title: 'STATUS',
			dataIndex: 'isActive',
			scopedSlots: { customRender: 'isActive' },
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			align: "center"
		},
	];

	
	export default {
		components: {
		},
		data() {
			return {

				pagination: {
					search: '',
					loading: false,
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
                    total: 10
                },

				form: this.$form.createForm(this, { name: 'newItemForm' }),

				columns,
				message: '',

				classrooms: [],
				academicYears: [],
				grades: [],
				sections: [],

				classroom: {
					uuid: null,
					academicYearUuid: null,
					gradeUuid: null,
					sectionUuid: null,
					description: null,
					isCreate: true,
					loading: false,
					showModal: false,
				},
			

			}
		},
        computed: {
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
        },
		watch: {
            pagination: {
                handler() {
                    this.getClassrooms();
                },
            },
            deep: true,
        },
		created() {
			this.getClassrooms();
			this.getAcademicYears();
			this.getGrades();
			this.getSections();
		},
		methods: {

			onSearchItem() {
				if(this.pagination.search != null && this.pagination.search.length > 0) {
					this.getClassrooms()
				}else{
					let message = "Please enter something to search"
					let type = "danger"

					this.notify(type, message)
				}
			},

			onClearSearchItem() {
				this.pagination.search = '';

				this.getClassrooms();
			},

			onCurrentPageChange(value) {
				this.pagination.currentPage = value;

				this.getClassrooms();
			},

			onPerPageChange(value) {
                this.pagination.perPage = value;

                this.getClassrooms();
            },


			getClassrooms() {
                this.pagination.loading = true;

                const { currentPage, perPage, search } = this.pagination;

                let url = `${this.$BACKEND_URL}/classrooms/?search=${search}&page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.classrooms = response.data.data;
                            this.pagination.totalPages = response.data.totalPages;
                            this.pagination.total = response.data.total;
                            this.pagination.currentPage = response.data. currentPage;
                        }else{
                            let message = "There is no data available"
							let type = "success"

                    		this.notify(type, message)
                        }
                    }
                    this.pagination.loading = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                    this.pagination.loading = false;
                });
            },


			getAcademicYears() {

                const currentPage = 1;
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/academic-years?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.academicYears = response.data.data;
                    }

                }).catch((error) => {

                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                });
            },
	
            getGrades() {

                const currentPage = 1;
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/grades?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.grades = response.data.data;
                    }
                    
                }).catch((error) => {
                    
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                });
            },
	
            getSections() {

                const currentPage = 1;
				const perPage = 100;

                let url = `${this.$BACKEND_URL}/sections?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.sections = response.data.data;
                    }
                    
                }).catch((error) => {
                    
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "danger"

                    this.notify(type, message)

                });
            },

			onCreateClassroom() {
                this.classroom.uuid = null;
				this.classroom.academicYearUuid = null;
				this.classroom.gradeUuid = null;
				this.classroom.sectionUuid = null;
				this.classroom.description = null;
				this.classroom.isCreate = true;
				this.classroom.loading = false;
				this.classroom.showModal = true;
            },

			async handleSubmit() {

				this.classroom.loading = true;
	
				let url = `${this.$BACKEND_URL}/classrooms`
				
				this.classroom.uuid = undefined;
				
				this.$AXIOS.post(url, this.classroom).then(response => {

					if (response.status >= 200 && response.status < 210) {

						this.form.resetFields()

						this.classroom.uuid = null;
						this.classroom.academicYearUuid = null;
						this.classroom.gradeUuid = null;
						this.classroom.sectionUuid = null;
						this.classroom.description = null;
						this.classroom.isCreate = true;
						this.classroom.loading = false;
						this.classroom.showModal = false;

						let message = response.data.message
						let type = "success"

						this.notify(type, message)

						this.getClassrooms()

					}

				}).catch(error => {
				
					this.classroom.loading = false;
		
					if(error.response != null && error.response.status == 401) {
						this.$router.push(`/`);
					}

					let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
					let type = "danger"

					this.notify(type, message)
		
				});
				
			},

			notify(type, message) {
				this.$notification.open({
					class: 'ant-notification-' + type,
					message: message,
					duration: 3.0,
					style: {fontSize: "10px !important"}
				});
			},

		},

		
	}

</script>

<style lang="scss">

	#txtBtn, #txtBtn:hover {
		border: none;
	}
</style>